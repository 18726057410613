var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showLoading)?_c('div',[_c('p',{staticStyle:{"text-align":"center","margin-top":"15px"}},[_vm._v(" "+_vm._s(_vm.$tc('message.aguarde_carregamento', 1))+" ")])]):_vm._e(),(!_vm.showLoading)?_c('v-data-table',{attrs:{"headers":_vm.cabecalhoFixo,"items":_vm.tipoAcao.acoes,"options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
      itemsPerPageOptions: [5, 10, 20],
    },"item-key":"id_trade_previa","expanded":_vm.expanded,"show-expand":""},on:{"update:options":function($event){_vm.pagination=$event},"update:expanded":function($event){_vm.expanded=$event},"item-expand":function($event){}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
    var expand = ref.expand;
    var isExpanded = ref.isExpanded;
return [_c('tr',[_c('td',{staticStyle:{"width":"2%","text-align":"center"}},[((_vm.acaoEmCadastro(item) && _vm.canEdit) || _vm.mostrarBotaoCancelarAcao(item))?_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":'more_vert_' + item.id,"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[((_vm.acaoEmCadastro(item) || _vm.acaoEmAnalise(item))  && _vm.canEdit)?_c('v-list-item',{on:{"click":function($event){return _vm.editarAcao(item.id_trade_previa)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc('label.editar', 1)))])],1):_vm._e(),((_vm.acaoEmCadastro(item) || _vm.acaoEmAnalise(item)) && _vm.canEdit)?_c('v-list-item',{on:{"click":function($event){return _vm.validaConcluir(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("thumb_up")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc('label.concluir2', 1)))])],1):_vm._e(),(_vm.mostrarBotaoCancelarAcao(item))?_c('v-list-item',{on:{"click":function($event){return _vm.openConfirmExclusaoAcao(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("cancel")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc('label.excluir', 1)))])],1):_vm._e()],1)],1):_vm._e()],1),_vm._l((_vm.colunasPorTipoAcao()),function(col){return _c('td',{key:col},[(col === 'status')?_c('span',[_c('center',[_c('status',{attrs:{"status":item.status,"tooltip":item.status}}),_vm._v(" "+_vm._s(_vm.$tc(("status_entidade." + (item.status.toLowerCase())), 1))+" ")],1)],1):(col === 'tipo_pagamento')?_c('span',[_c('center',[_vm._v(" "+_vm._s(_vm.$tc(("tipos_pagamentos." + (item.tipo_pagamento.toUpperCase())), 1))+" ")])],1):(col === 'data-table-expand')?_c('span',[_vm._v(" ... ")]):_c('span',[_c('center',[_vm._v(" "+_vm._s(_vm.formataValorColuna(col, item))+" ")])],1)])}),_c('td',[(!isExpanded)?_c('v-icon',{staticClass:"fa-sm",on:{"click":function($event){return _vm.expandirLinha(item)}}},[_vm._v("fa-chevron-down")]):_vm._e(),(isExpanded)?_c('v-icon',{staticClass:"fa-sm",on:{"click":function($event){return _vm.fecharLinha(item)}}},[_vm._v("fa-chevron-up")]):_vm._e()],1)],2)]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(!_vm.showLoadingDetalhe)?_c('v-data-table',{attrs:{"headers":_vm.cabecalhoDetalheFixo,"items":_vm.tipoAcao.acoesMassivas,"options":_vm.paginationDetalhe,"server-items-length":_vm.totalPageDetalhe,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
            itemsPerPageOptions: [5, 10, 20],
          }},on:{"update:options":function($event){_vm.paginationDetalhe=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('tr',_vm._l((_vm.colunasPorTipoAcaoMassiva()),function(col){return _c('td',{key:col},[(col === 'status')?_c('span',[_c('center',[_c('status',{attrs:{"status":item.status,"tooltip":item.status}}),_vm._v(" "+_vm._s(_vm.$tc(("status_entidade." + (item.status.toLowerCase())), 1))+" ")],1)],1):(col === 'valor')?_c('span',[_c('center',[_c('span',[_vm._v(_vm._s(_vm.formatarValorAcao(item.valor, item.forma_bonificacao)))])])],1):_c('span',[_c('center',[_vm._v(" "+_vm._s(_vm.formataValorColuna(col, item))+" ")])],1)])}),0)]}}],null,true)}):_vm._e()],1)]}}],null,false,2161809525)}):_vm._e(),_c('planejamento-acao-justificativa',{ref:"modalJustificativa",attrs:{"obrigatorio":_vm.justificativaObrigatoria,"salvarJustificativa":_vm.actionJustificativa,"observacao":_vm.justificativa,"somenteLeitura":_vm.justificativaSomenteLeitura,"id-acao":_vm.acaoSelecionada.id}}),_c('confirm',{ref:"confirmExclusaoAcao",attrs:{"message":_vm.$t('title.confirm_aprov_mass_irrev'),"persistent":true},on:{"agree":_vm.cancelarAcao}}),_c('confirm',{ref:"concluirAcaoSemFluxoDialog",attrs:{"max-width":'500',"message":_vm.$t('message.concluir_acao_sem_fluxo_massivo'),"persistent":true},on:{"agree":_vm.concluirAcao}}),_c('confirm',{ref:"concluirAcaoOrcamentoAtrasado",attrs:{"max-width":'500',"message":_vm.$t('message.concluir_acao_orcamento_atrasado'),"persistent":true},on:{"agree":_vm.concluirAcao}}),_c('div',{attrs:{"id":"spinner-loading-local"}},[_c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":100,"width":3,"color":"primary"}})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }