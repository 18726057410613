<template>
  <v-card style="margin-bottom:1em" class="PlanejamentoAcaoFiltros">
    <v-card-title>
      <span class="title">{{ $tc('title.filtro', 2) }}</span>
      <v-spacer></v-spacer>
      <v-icon v-show="filtrosAbertos"
        @click="fixaFiltros"
        :class="filtrosFixosClass">
        fa-thumbtack
      </v-icon>

      <icon-filtro @click="toggleFiltros" :filtros="{...filtrosPlanejamentoAcao}" :min="1" />
    </v-card-title>

    <v-container fluid grid-list-md v-show="filtrosAbertos">
      <metadados-container-layout
        :key="containerKey"
        :ordenacao-campos="ordenacaoCampos"
        :campos-formulario="camposFormulario"
        :formulario-filtros="true"
        :ignora-obrigatorios="false"
        :input-layout="inputLayout"
        :objeto="filtrosPlanejamentoAcao"
        ref="container">
      </metadados-container-layout>
    </v-container>
    <v-card-actions v-show="filtrosAbertos">
      <v-spacer></v-spacer>
      <v-btn @click="resetaFiltros"
        color="accent"
      >{{$t('label.limpar_filtros')}}</v-btn>
      <v-btn @click="aplicarFiltros"
        color="primary"
      >{{$t('label.filtrar')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  skipLoading,
} from '@/produto/common/functions/loading';
import { copyObject } from '../../../produto/common/functions/helpers';

import MetadadosContainerLayout from '../../../produto/shared-components/metadados/MetadadosContainerLayout';
import PlanejamentoAcaoMassivoFiltrosCampos from './PlanejamentoAcaoMassivoFiltrosCampos';
import IconFiltro from '../../../produto/shared-components/filtros/IconFiltro';

export default {
  name: 'PlanejamentoAcaoFiltros',
  mixins: [
    PlanejamentoAcaoMassivoFiltrosCampos,
  ],
  components: {
    MetadadosContainerLayout,
    IconFiltro,
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  data() {
    return {
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),

      filtrosAbertos: false,
      carregouCampos: false,

      metadadosPlanejamentoAcao: null,
      containerKey: 0,

      ordenacaoCampos: [
        'cod_planejamento_massivo',
        'cod_acao_tatica',
        'status_planejamento_massivo',
        'hierarquia',
        'tipo_pagamento',
        'tipo_verba',
        'tipo_acao',
        'divisao',
        'unidadenegocio',
        'usuario',
        'ano',
        'periodo_planejamento',
        'cliente',
        'holding',
      ],

      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
      workspace: {},

      filtrosPlanejamentoAcao: {},
      entidadeWorkspace: 'planejamento_acao_massivo',
    };
  },
  methods: {
    carregarWorkspaceFiltros() {
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources.pesquisar({ entidade })
        .then((response) => {
          this.workspace = response.data || {};
          this.filtrosFixados = this.workspace.indAberto || false;
          this.filtrosAbertos = this.filtrosFixados;
          this.filtrosPlanejamentoAcao = this.workspace.filtros || {};

          this.filtrosPlanejamentoAcao.buscaEfetivada = true;

          if (!this.filtrosPlanejamentoAcao.status_planejamento_massivo) {
            this.filtrosPlanejamentoAcao.status_planejamento_massivo = ['EM_CADASTRO'];
          }

          this.unidadesNegocioDivisao = [this.filtrosPlanejamentoAcao.unidadeNegocio];

          this.tipoFiltro = this.filtrosPlanejamentoAcao.tipoFiltro || 'comum';

          setTimeout(() => this.restaurarFiltros(), 500);
        }).catch((err) => {
          this.$error(this, err);
        }).finally(() => {
          this.carregouCampos = true;
        });
    },
    toggleFiltros() {
      this.filtrosAbertos = !this.filtrosAbertos;
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources
        .inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources
        .atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    resetaFiltros() {
      this.containerKey += 1;
      this.filtrosPlanejamentoAcao = {
        ano: {
          ano: null,
        },
        periodo_planejamento: {},
      };
      this.$refs.container.refresh();
      setTimeout(() => this.aplicarFiltros());
    },
    restaurarFiltros() {
      const parametros = {
        ...this.filtrosPlanejamentoAcao,
      };
      const idAcaoPrevia = parametros.cod_planejamento_massivo ? parametros.cod_planejamento_massivo : null;
      const idAcao = parametros.cod_acao_tatica ? parametros.cod_acao_tatica : null;
      const listaStatus = [];
      const hierarquia = [];
      const idsTipoPagamento = [];
      const idsTipoVerba = [];
      const idsAcaoTipo = [];
      const idsDivisao = [];
      const idsUnidadeNegocio = [];
      const idsUsuario = [];
      const valorAno = parametros.ano !== undefined ? parametros.ano.ano : null;
      const idsPeriodo = [];
      const idsClientes = [];
      const idsHoldings = [];

      if (parametros.status_planejamento_massivo !== undefined) {
        parametros.status_planejamento_massivo.forEach((c) => {
          listaStatus.push(c);
        });
      }
      if (parametros.hierarquia !== undefined) {
        parametros.hierarquia.forEach((c) => {
          hierarquia.push(c.nomExtensao);
        });
      }
      if (parametros.tipo_pagamento !== undefined) {
        parametros.tipo_pagamento.forEach((c) => {
          idsTipoPagamento.push(c.id);
        });
      }
      if (parametros.tipo_verba !== undefined) {
        parametros.tipo_verba.forEach((c) => {
          idsTipoVerba.push(c.id);
        });
      }
      if (parametros.tipo_acao !== undefined) {
        parametros.tipo_acao.forEach((c) => {
          idsAcaoTipo.push(c.id);
        });
      }
      if (parametros.divisao !== undefined) {
        parametros.divisao.forEach((c) => {
          idsDivisao.push(c.id);
        });
      }
      if (parametros.unidadenegocio !== undefined) {
        parametros.unidadenegocio.forEach((c) => {
          idsUnidadeNegocio.push(c.id);
        });
      }
      if (parametros.cliente !== undefined) {
        parametros.cliente.forEach((c) => {
          idsClientes.push(c.id);
        });
      }
      if (parametros.holding !== undefined) {
        parametros.holding.forEach((h) => {
          idsHoldings.push(h.id);
        });
      }

      if (parametros.usuario !== undefined) {
        parametros.usuario.forEach((u) => {
          idsUsuario.push(u.idExterno);
        });
      }

      if (parametros.periodo_planejamento !== undefined) {
        parametros.periodo_planejamento.forEach((p) => {
          idsPeriodo.push(p.mes);
        });
      }

      const filtros = {
        id_acao_previa: idAcaoPrevia,
        id_acao: idAcao,
        status: listaStatus,
        hierarquias: hierarquia,
        id_tipo_pagamento: idsTipoPagamento,
        id_tipo_verba: idsTipoVerba,
        id_acao_tipo: idsAcaoTipo,
        id_divisao: idsDivisao,
        id_unidadenegocio: idsUnidadeNegocio,
        id_usuario_criacao: idsUsuario,
        ano: valorAno,
        id_periodo: idsPeriodo,
        id_cliente: idsClientes,
        id_holding: idsHoldings,
        buscaEfetivada: true,
      };
      skipLoading();
      this.$emit('PlanejamentoAcaoFiltros__AplicaFiltros', filtros);
    },
    aplicarFiltros() {
      this.restaurarFiltros();
      this.salvarFiltrosWorkspace();
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosPlanejamentoAcao;
      this.workspace.filtros.tipoFiltro = this.tipoFiltro;

      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    preencheHoldings() {
      this.configuracaoResource
        .buscarHoldingsCliente()
        .then((res) => {
          if (this.filtrosPlanejamentoAcao.holding === null
                || this.filtrosPlanejamentoAcao.holding === undefined) {
            this.filtrosPlanejamentoAcao.holding = res.body;
            this.$refs.container.refresh();
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    const metadados = this.getAcaoMetadado;
    this.metadadosPlanejamentoAcao = copyObject(metadados);
    this.carregarWorkspaceFiltros();
  },
};
</script>
<style>
  .PlanejamentoAcoesFiltros__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .PlanejamentoAcoesFiltros__Opcoes div.v-input__slot,
  .PlanejamentoAcoesFiltros__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .PlanejamentoAcoesFiltros__Opcoes div.v-messages {
    display: none;
  }
  .PlanejamentoAcoesFiltros__Opcoes label {
    font-size: 14px;
  }
</style>
