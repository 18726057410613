<style>
.PlanejamentoAcaoList {
  padding-bottom: 60px;
}
.PlanejamentoAcaoList__Lista {
  margin-top: 20px;
}
.v-dialog {
  width: auto;
}
</style>
<template>
  <div class="PlanejamentoAcaoList">
    <planejamento-acao-filtros
      ref="filtros"
      @PlanejamentoAcaoFiltros__AplicaFiltros="aplicaFiltros">
    </planejamento-acao-filtros>
    <div style="padding: 8px; padding-bottom: 15px;" class="text-right">
      <v-btn color="primary" @click="criarNovaAcao()">{{ $t('label.novo_planejamento') }} +</v-btn>
    </div>
    <planejamento-acao-dados
      ref="dados"
      @PlanejamentoAcaoDados__AplicaFiltros="alterarAgrupamento">
    </planejamento-acao-dados>

    <!-- <v-btn fab
      bottom right fixed
      class="v-btn__fab"
      color="primary"
      @click.native="criarNovaAcao()"
      v-show="canEdit">
      <v-icon>add</v-icon> -->
    </v-btn>
  </div>
</template>
<script>

import PlanejamentoAcaoFiltros from '@/spa/planejamento-acao-massivo/list/PlanejamentoAcaoFiltros';
import PlanejamentoAcaoDados from '@/spa/planejamento-acao-massivo/list/PlanejamentoAcaoDados';
import { generateComputed } from '../../../produto/common/functions/roles-computed-generator';

export default {
  components: {
    PlanejamentoAcaoFiltros,
    PlanejamentoAcaoDados,
  },
  computed: {
    ...generateComputed('ACAO', [
      'canAccessPage',
      'canEdit',
    ]),
    ...generateComputed('ACESSO_CLIENTE', [
      'getAllRoles',
    ]),
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      exibeModalNovaAcao: false,
      filtroTipo: null,
      filtros: {},
      quantidadePagina: 1,
    };
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        if (!this.getAllRoles
            || !this.getAllRoles.filter((el) => el.startsWith('ACESSO_CLIENTE')).length) {
          this.$router.push({ name: 'inicio' });
          this.$toast(this.$t('message.acesso_negado'));
        }
      }
    },
    criarNovaAcao() {
      this.$router.push({ name: 'novaAcaoMassiva' });
    },
    fecharModalNovaAcao() {
      this.exibeModalNovaAcao = false;
    },
    alterarAgrupamento() {
      this.$refs.dados.aplicaFiltros(this.filtros);
    },
    aplicaFiltros(filtros = {}) {
      this.filtros = filtros;
      setTimeout(() => this.$refs.dados.aplicaFiltros(filtros), 500);
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
};
</script>
