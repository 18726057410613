<template>
  <div>
    <div v-if="showLoading">
      <p style="text-align: center; margin-top:15px">
        {{ $tc('message.aguarde_carregamento', 1) }}
      </p>
    </div>
    <v-data-table
      :headers="cabecalhoFixo"
      v-if="!showLoading"
      :items="tipoAcao.acoes"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20],
      }"
      item-key="id_trade_previa"
      :expanded.sync="expanded"
      @item-expand
      show-expand>
      <template v-slot:item="{item, expand, isExpanded }">
        <tr>
          <td style="width: 2%; text-align: center;">
            <v-menu bottom
              origin="center center"
              v-if="(acaoEmCadastro(item) && canEdit) || mostrarBotaoCancelarAcao(item)"
              transition="scale-transition">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :id="'more_vert_' + item.id"
                  icon>
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="(acaoEmCadastro(item) || acaoEmAnalise(item))  && canEdit"
                  @click="editarAcao(item.id_trade_previa)">
                  <v-list-item-action>
                    <v-icon>edit</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>{{ $tc('label.editar', 1) }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="(acaoEmCadastro(item) || acaoEmAnalise(item)) && canEdit"
                  @click="validaConcluir(item)">
                  <v-list-item-action>
                    <v-icon>thumb_up</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>{{ $tc('label.concluir2', 1) }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="mostrarBotaoCancelarAcao(item)"
                  @click="openConfirmExclusaoAcao(item)">
                  <v-list-item-action>
                    <v-icon>cancel</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>{{ $tc('label.excluir', 1) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
          <td v-for="col in colunasPorTipoAcao()" :key="col">
            <span v-if="col === 'status'">
            <center>
                <status
                  :status="item.status"
                  :tooltip="item.status">
                </status>
                {{$tc(`status_entidade.${item.status.toLowerCase()}`, 1)}}
              </center>
            </span>
            <span v-else-if="col === 'tipo_pagamento'">
              <center>
                {{ $tc(`tipos_pagamentos.${item.tipo_pagamento.toUpperCase()}`, 1) }}
              </center>
            </span>
            <span v-else-if="col === 'data-table-expand'">
              ...
            </span>
            <span v-else>
              <center>
                {{formataValorColuna(col, item)}}
              </center>
            </span>
          </td>
          <td>
            <v-icon  @click="expandirLinha(item)" v-if="!isExpanded" class="fa-sm">fa-chevron-down</v-icon>
            <v-icon  @click="fecharLinha(item)" v-if="isExpanded" class="fa-sm">fa-chevron-up</v-icon>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }" style="width: 100%;">
        <td :colspan="headers.length">
          <v-data-table
            :headers="cabecalhoDetalheFixo"
            v-if="!showLoadingDetalhe"
            :items="tipoAcao.acoesMassivas"
            :options.sync="paginationDetalhe"
            :server-items-length="totalPageDetalhe"
            :no-data-text="$t('label.tabela_sem_conteudo')"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 20],
            }">
            <template v-slot:item="{ item }">
              <tr>
                <td v-for="col in colunasPorTipoAcaoMassiva()" :key="col">
                   <span v-if="col === 'status'">
                    <center>
                      <status
                        :status="item.status"
                        :tooltip="item.status">
                      </status>
                      {{$tc(`status_entidade.${item.status.toLowerCase()}`, 1)}}
                    </center>
                  </span>
                  <span v-else-if="col === 'valor'">
                    <center>
                      <span>{{ formatarValorAcao(item.valor, item.forma_bonificacao) }}</span>
                    </center>
                  </span>
                  <span v-else>
                    <center>
                      {{formataValorColuna(col, item)}}
                    </center>
                  </span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
    <planejamento-acao-justificativa
      ref="modalJustificativa"
      :obrigatorio="justificativaObrigatoria"
      :salvarJustificativa="actionJustificativa"
      :observacao="justificativa"
      :somenteLeitura="justificativaSomenteLeitura"
      :id-acao="acaoSelecionada.id"
    >
    </planejamento-acao-justificativa>

    <confirm
        ref="confirmExclusaoAcao"
        :message="$t('title.confirm_aprov_mass_irrev')"
        :persistent="true"
        @agree="cancelarAcao">
    </confirm>
    <confirm
      ref="concluirAcaoSemFluxoDialog"
      :max-width="'500'"
      :message="$t('message.concluir_acao_sem_fluxo_massivo')"
      :persistent="true"
      @agree="concluirAcao"/>
    <confirm
      ref="concluirAcaoOrcamentoAtrasado"
      :max-width="'500'"
      :message="$t('message.concluir_acao_orcamento_atrasado')"
      :persistent="true"
      @agree="concluirAcao"/>
    <div id="spinner-loading-local">
      <div>
        <v-container fluid>
          <v-row align="center" justify="center">
            <v-progress-circular
              indeterminate
              :size="100"
              :width="3"
              color="primary"
            />
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import PlanejamentoAcaoJustificativa from '@/spa/planejamento-acao/PlanejamentoAcaoJustificativa';
import { buscarAcoes, buscarAcoesSimplificado } from '@/common/resources/suzano-planejamento-acao-listagem-massiva';
import { generateComputed } from '../../../produto/common/functions/roles-computed-generator';
import { getMoney, getPercent } from '../../../produto/common/functions/helpers';
import Status from '../../../produto/shared-components/Status';
import Confirm from '../../../produto/shared-components/vuetify/dialog/Confirm';
import { skipLoading } from '../../../produto/common/functions/loading';
import {
  convertToMoney,
  convertToPercent,
} from '../../../produto/common/functions/numeric';

export default {
  components: {
    Status,
    Confirm,
    PlanejamentoAcaoJustificativa,
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...generateComputed('ACAO', [
      'canEdit',
    ]),
    metadadosAcao() {
      if (this.getAcaoMetadado && this.getAcaoMetadado.mapaEntidades) {
        return this.getAcaoMetadado;
      }
      return undefined;
    },
  },
  props: {
    tipoAcao: Object,
    ordenacao: Array,
    filtros: Object,
    agrupamento: String,
    labelCampoCliente: {
      type: String,
      default: '',
    },
  },
  watch: {
    pagination: {
      handler() {
        if (this.mesmaPagina(this.pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = this.pagination;
        if (this.filtros.buscaEfetivada === true) {
          this.buscarAcoes(this.filtros);
        }
      },
      deep: true,
    },
    paginationDetalhe: {
      handler() {
        if (this.mesmaPagina(this.paginationDetalhe, this.paginationDetalhe)) {
          return;
        }
        this.lastPaginationDetalhe = this.paginationDetalhe;
        this.buscarAcoesSimplificado();
      },
      deep: true,
    },
  },
  data() {
    return {
      orcamentoResource: this.$api.orcamento(this.$resource),
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      planejamentoAcaoMassivoResource: this.$api.suzanoPlanejamentoAcaoListagemMassiva(this.$resource),
      totalPage: 0,
      totalPageDetalhe: 0,
      pagination: {},
      paginationDetalhe: {},
      acaoExclusao: {},
      lastPagination: {},
      lastPaginationDetalhe: {},
      acoesAprovacao: null,
      showLoading: false,
      showLoadingDetalhe: false,
      expanded: [],
      cabecalhoFixo: [
        {
          text: 'Ações', value: 'acoes', sortable: false, width: '2%', align: 'center',
        },
        {
          text: 'Cód. Planejamento Massivo', value: 'id_trade_previa', sortable: false, width: '10%', align: 'center',
        },
        {
          text: this.$tc('title.foco_da_acao', 1), value: 'cliente_customer', sortable: false, width: '10%', align: 'center',
        },
        {
          text: this.$tc('label.regional', 1), value: 'nom_regional', sortable: false, width: '10%', align: 'center',
        },
        {
          text: this.$tc('label.data_inicio', 1),
          value: 'data_inicio',
          sortable: false,
          width: '10%',
          align: 'center',
        },
        {
          text: this.$tc('label.data_fim', 1), value: 'data_fim', sortable: false, width: '10%', align: 'center',
        },
        {
          text: this.$tc('label.tipo_verba', 1), value: 'tipo_verba', sortable: false, width: '10%', align: 'center',
        },
        {
          text: this.$tc('label.tipo_pagamento', 1), value: 'tipo_pagamento', sortable: false, width: '10%', align: 'center',
        },
        {
          text: this.$tc('label.status', 1), value: 'status', sortable: false, align: 'center', width: '10%',
        },
        {
          text: 'Expandir', value: 'data-table-expand', width: '2%',
        },
      ],
      cabecalhoDetalheFixo: [
        {
          text: 'Cód. Ação Tática', value: 'id_acao', sortable: false, width: '10%', align: 'center',
        },
        {
          text: 'Descrição', value: 'descricao', sortable: false, width: '30%', align: 'center',
        },
        {
          text: this.$tc('label.hierarquia'), value: 'hierarquia1', sortable: false, width: '10%', align: 'center',
        },
        {
          text: this.$tc('label.unidade_negocio', 1), value: 'unidadenegocio', sortable: false, width: '10%', align: 'center',
        },
        {
          text: this.$tc('label.valor', 1),
          value: 'valor',
          sortable: false,
          width: '10%',
          align: 'center',
        },
        {
          text: this.$tc('label.tipo_acao', 1), value: 'tipo_acao', sortable: false, width: '10%', align: 'center',
        },
        {
          text: this.$tc('label.status', 1), value: 'status', sortable: false, align: 'center', width: '20%',
        },
      ],
      pesquisaValida: true,
      justificativaObrigatoria: false,
      actionJustificativa() {},
      acaoSelecionada: {},
      justificativa: '',
      justificativaSomenteLeitura: false,
      statusPassos: {},
      acaoMassivaEditar: null,
      acaoConcluir: {
        type: Function,
        default: () => ({}),
      },
    };
  },
  methods: {
    validaConcluir(item) {
      this.acoesAprovacao = null;
      this.acoesAprovacao = item;
      if (this.acoesAprovacao.orcamento_90_dias) {
        this.$refs.concluirAcaoOrcamentoAtrasado.open();
      } else {
        this.$refs.concluirAcaoSemFluxoDialog.open();
      }
    },
    getMoney,
    getPercent,
    convertToMoney,
    convertToPercent,
    formatarValorAcao(valor, formaBonificacao) {
      if (formaBonificacao !== 'PERCENTUAL') {
        return this.convertToMoney(valor);
      }
      return this.convertToPercent(valor);
    },
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    validarDatas(filtros) {
      const dataInicial = moment(filtros.data_inicio);
      const dataFinal = moment(filtros.data_fim);

      if (filtros.data_inicio !== undefined && filtros.data_fim === undefined) {
        this.$toast(this.$t('errors.dta_fim.obrigatoria'));
        return false;
      }

      if (dataFinal.isBefore(dataInicial)) {
        this.$toast(this.$t('message.data_inicial_maior_final'));
        return false;
      }
      return true;
    },
    forceLoading() {
      skipLoading();
      const element = document.getElementById('spinner-loading-local');
      if (element != null && element.style.display !== 'block') {
        element.style.display = 'block';
      }
    },
    forceHideLoading() {
      skipLoading();
      const element = document.getElementById('spinner-loading-local');
      if (element != null && element.style.display !== 'none') {
        element.style.display = 'none';
      }
    },
    buscarAcoes(filtros) {
      if (filtros !== null && filtros !== undefined) {
        this.pesquisaValida = this.validarDatas(filtros);
      }
      if (this.pesquisaValida) {
        this.tipoAcao.acoes = [];
        this.tipoAcao.acoesMassivas = [];
        this.expanded = [];

        const params = {
          ...filtros,
          numeroPagina: this.pagination.page,
          tamanhoPagina: this.pagination.itemsPerPage,
        };

        if (this.tipoAcao.idAgrupamento) {
          params.id_agrupamento = this.tipoAcao.idAgrupamento;
          params.agrupamento = this.agrupamento;
        }
        skipLoading();
        this.showLoading = true;
        this.forceLoading();
        buscarAcoes(params, this.$resource)
          .then((res) => {
            this.totalPage = res.data.quantidadeRegistrosPagina;
            this.tipoAcao.acoes = [...res.data.resposta];
            setTimeout(() => this.$forceUpdate());
            this.showLoading = false;
            this.forceHideLoading();
          })
          .catch((err) => {
            this.$toast(err.data.error);
            this.forceHideLoading();
          });
      }
    },
    async buscarAcoesSimplificado(idAcaoTrade) {
      const parametros = {
        id_acao_previa: idAcaoTrade,
        numeroPagina: this.paginationDetalhe.page,
        tamanhoPagina: this.paginationDetalhe.itemsPerPage,
      };
      if (idAcaoTrade !== null && idAcaoTrade !== undefined) {
        await buscarAcoesSimplificado(parametros, this.$resource).then((res) => {
          this.totalPageDetalhe = res.data.quantidadeRegistrosPagina;
          this.tipoAcao.acoesMassivas = res.data.resposta;
        });
      }
    },
    acaoAprovada(acao) {
      const { status } = acao;
      return status === 'APROVADO';
    },
    acaoEmCadastro(acao) {
      const { status } = acao;
      return status === 'EM_CADASTRO';
    },
    acaoEmAnalise(acao) {
      const { status } = acao;
      return status === 'EM_ANALISE';
    },
    acaoCancelada(acao) {
      const { status } = acao;
      return status === 'CANCELADO';
    },
    editarAcao(idAcao) {
      this.$router.push({ name: 'editarAcaoMassiva', params: { idAcao, from: 'planejamento-acao-massivo' } });
    },
    cabecalhoPorTipoAcao() {
      const { tipoAcao } = this;

      if (tipoAcao.cabecalho) return tipoAcao.cabecalho;

      const ordenacao = this.colunasPorTipoAcao(),
        cabecalho = [];

      ordenacao.forEach((col) => {
        let isColFixa = false;
        for (let i = 0; i < this.cabecalhoFixo.length; i += 1) {
          const defCol = this.cabecalhoFixo[i];
          isColFixa = defCol.value === col;
          if (isColFixa) {
            if (defCol.value === 'campo_cliente') {
              defCol.text = `${this.$tc('label.foco_acao', 1)} - ${this.labelCampoCliente}`;
            }
            cabecalho.push(defCol);
            break;
          }
        }
        if (!isColFixa) {
          cabecalho.push({
            text: this.tituloCampoDinamico(col),
            value: col,
            sortable: false,
          });
        }
      }, this);

      const colAcoes = this.cabecalhoFixo.filter((c) => c.value === 'acoes')[0];
      tipoAcao.cabecalho = [colAcoes, ...cabecalho];

      return tipoAcao.cabecalho;
    },
    colunasPorTipoAcao() {
      return ['id_trade_previa', 'cliente_customer', 'nom_regional', 'data_inicio', 'data_fim', 'tipo_verba', 'tipo_pagamento', 'status'];
    },
    colunasPorTipoAcaoMassiva() {
      return ['id_acao', 'descricao', 'hierarquia1', 'unidadenegocio', 'valor', 'tipo_acao', 'status'];
    },
    tituloCampoDinamico(nomeCampo) {
      const dependencia = this.getDepenciaMetadadosPorNome(nomeCampo);
      if (dependencia != null) {
        return dependencia.entidadeEstrangeira;
      }
      const campoDinamico = this.getCampoDinamicoPorNome(nomeCampo);
      if (!campoDinamico) {
        $logger.log(nomeCampo);
      }
      return campoDinamico.nomCampo;
    },
    formataValorColuna(nomeColuna, item) {
      return item[nomeColuna];
    },
    getDepenciaMetadadosPorNome(nomeCampo) {
      const dependencias = this.metadadosAcao.mapaEntidades,
        dependencia = dependencias[nomeCampo];
      return dependencia;
    },
    getCampoDinamicoPorNome(nomeCampo) {
      const campos = this.metadadosAcao.mapaCamposDinamicos,
        campo = campos[nomeCampo];
      return campo;
    },
    abrirJustificativaAprovar(item) {
      const justObrigatoria = false;
      this.abrirJustificativaAvaliar(item, this.aprovar, justObrigatoria);
    },
    aprovar(justificativa) {
      const idAcao = this.acaoSelecionada.id;
      this.workflowAcaoResource.aprovarPasso({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.buscarStatusFluxo(this.acaoSelecionada, true, () => {
            if (this.acaoSelecionada.fluxo.statusFluxo === 'APROVADO') {
              this.acaoSelecionada.status = 'APROVADO';
              this.$forceUpdate();
            }
          });
          this.acaoSelecionada.aprovadorFluxo = false;
          this.$forceUpdate();
          this.$toast(this.$t('message.acao_aprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    expandirLinha(item) {
      this.tipoAcao.acoesMassivas = [];
      this.totalPageDetalhe = 0;
      if (this.expanded.length > 0) {
        this.expanded = [];
      }

      this.expanded.push(item);
      this.buscarAcoesSimplificado(item.id_trade_previa);
    },
    fecharLinha(item) {
      const index = this.expanded.findIndex((i) => i === item);

      if (index > -1) {
        this.expanded.splice(index, 1);
      }
    },
    openConfirmExclusaoAcao(item) {
      this.acaoExclusao = item;
      this.$refs.confirmExclusaoAcao.open();
    },
    async cancelarAcao() {
      const parametros = {
        id_acao_previa: this.acaoExclusao.id_trade_previa,
        numeroPagina: 0,
        tamanhoPagina: 1000,
      };
      this.forceLoading();
      const res = await buscarAcoesSimplificado(parametros, this.$resource);

      // eslint-disable-next-line no-restricted-syntax
      for (const ac of res.data.resposta) {
        if (ac.status === 'EM_CADASTRO') {
          const param = { idAcao: ac.id };

          // eslint-disable-next-line no-await-in-loop
          await this.planejamentoAcaoResource.cancelarAcao(param, param)
            .then(async () => {
              this.$toast(this.$t('message.acao_massiva_excluida_sucesso', { id: ac.id }));
            })
            .catch((err) => {
              this.$toast(err.data.error);
            });
        }
      }

      await this.buscarAcoes(this.filtros);
      this.forceHideLoading();
    },
    async concluirAcao() {
      this.forceLoading();
      this.planejamentoAcaoMassivoResource.concluirAcaoMassiva(this.acoesAprovacao.id_trade_previa)
        .then(() => {
          this.$toast(this.$t('message.acoes_concluidas'));
          this.forceHideLoading();
          this.buscarAcoes(this.filtros);
        })
        .catch((err) => {
          this.forceHideLoading();
          if (err.data.error === 'ja_aprovada') {
            this.$toast(this.$t('message.ja_aprovada'));
            return;
          }
          const erros = JSON.parse(err.bodyText);
          const listaErros = erros.erros[0].split('.,');
          listaErros.forEach((e) => {
            this.$toast(e);
          });
          this.blockerDuplicacao = false;
        });
    },
    buscarStatusFluxo(acao, recarregando, cb) {
      if (acao.status === 'REPROVADO' || acao.status === 'EM_ANALISE' || recarregando) {
        const idAcao = acao.id;
        skipLoading();
        this.workflowAcaoResource.status({ idAcao })
          .then((response) => {
            acao.fluxo = response.data;
            if (cb) cb();
          })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    abrirJustificativa(acao) {
      this.justificativa = acao.fluxo.passoAtual.observacao;
      this.justificativaSomenteLeitura = true;
      this.$refs.modalJustificativa.open();
    },
    mostrarBotaoCancelarAcao(item) {
      return this.canEdit && (
        (this.acaoEmCadastro(item) || this.acaoEmAnalise(item))
        || (this.acaoAprovada(item) && item.cancela_aprovada)
        || (item.solicitanteFluxo && this.acaoAguardandoAprovacao(item))
      );
    },
  },
};
</script>
<style>
.PlanejamentoAcaoTabela__ColunaGrande {
  min-width: 150px;
}
.PlanejamentoAcaoTabela__ColunaMedia {
  min-width: 120px;
}
#spinner-loading-local > div {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: #eae9e9;
  opacity: .7;
}
</style>
